import _ from 'lodash';
import { type ResolveTypes } from '@wix/tpa-router';
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';
import { type GetGroupIdBySlugResponse } from '@wix/ambassador-social-groups-v2-group/types';

import type { IGroup } from 'api/groups/types';
import type { StateDeclarationFn } from 'controller/types';
import { isOptimizationEnabled } from 'controller/ssr-optimization';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  const { experiments } = params.flowAPI;

  const optimization = isOptimizationEnabled(params);

  return {
    abstract: true,
    url: '/discussion',
    name: 'group.discussion',
    data: { sectionId: 'group' },
    async onExit() {
      await vm._.comments.dispose();
    },
    resolve: _.compact<ResolveTypes>([
      optimization && {
        token: 'feedAvailable',
        deps: ['partialGroup'],
        resolveFn(response: GetGroupIdBySlugResponse) {
          const { accessPermissions } = response;

          return accessPermissions?.feedPermissions?.canViewPosts ?? false;
        },
      },
      !optimization && {
        token: 'feedAvailable',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return selectIsAppAvailable(store.getState(), {
            groupId: group.id!,
            application: GroupAppKey.FEED_APP,
          });
        },
      },
      {
        token: 'membersAvailable',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return selectIsAppAvailable(store.getState(), {
            groupId: group.id!,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'eventsAvailable',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return selectIsAppAvailable(store.getState(), {
            groupId: group.id!,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'topics',
        deps: ['groupId', 'feedAvailable'],
        resolveFn(groupId: string, isFeedAvailable: boolean) {
          if (isFeedAvailable) {
            return vm.topics$.fetch(groupId);
          }
        },
      },
      {
        token: 'events',
        deps: ['group', 'eventsAvailable'],
        resolveFn(group: IGroup, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(group.id!);
          }
        },
      },
      {
        token: 'members',
        deps: ['group', 'membersAvailable'],
        resolveFn(group: IGroup, isMembersAvailable: boolean) {
          if (isMembersAvailable) {
            return vm.members$.fetch(group.id!, 5);
          }
        },
      },
      {
        token: 'onboarding',
        deps: ['group', 'isJoined'],
        resolveFn(group: IGroup, isJoined: boolean) {
          const admins = experiments.enabled('specs.groups.AdminOnboarding');
          const members = experiments.enabled('specs.groups.MemberOnboarding');

          const enabled = {
            [GroupRole.ADMIN]: admins,
            [GroupRole.MEMBER]: members,
            [GroupRole.UNKNOWN_ROLE]: false,
          };

          if (isJoined && enabled[group.role]) {
            vm.onboarding$.initialize(group.id!);
          }
        },
      },
    ]),
  };
} as StateDeclarationFn);
