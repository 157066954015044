import {
  GroupMemberWithProfile,
  PrivacyStatus,
  Role,
} from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import {
  Member,
  PrivacyStatusStatus,
} from '@wix/ambassador-members-v1-member/types';
import { ConnectedMembers } from '@wix/ambassador-members-v3-follow/types';

import type { IMembersBadges } from '../badges/types';

import type { IGroupMember, IMember } from './types';

/**
 * Map groups GroupMemberWithProfile to IGroupMember
 */
export function mapGroupMember({
  member,
  badges,
  connections = {},
}: {
  member: GroupMemberWithProfile;
  badges?: IMembersBadges;
  connections?: ConnectedMembers;
}): IGroupMember {
  const memberId = member.memberId as string;
  const isPrivate = member.profile?.privacyStatus === PrivacyStatus.PRIVATE;

  return {
    memberId,
    isPrivate,
    connections,
    siteMemberId: memberId,
    badges: badges?.[memberId] || [],
    name: member.profile?.nickname as string,
    imageUrl: member.profile?.imageUrl as string,
    role: member.role?.value || Role.UNKNOWN_ROLE,
    answersCount: member.membershipQuestionAnswersCount as number,
  };
}

/**
 * Map wix Member to IMember
 */
export function mapMember(member: Member, badges?: IMembersBadges): IMember {
  const memberId = member.id as string;
  const isPrivate = member.privacyStatus === PrivacyStatusStatus.PRIVATE;

  return {
    memberId,
    isPrivate,
    siteMemberId: memberId,
    slug: member.profile?.slug as string,
    badges: badges?.[memberId] || [],
    name: member.profile?.nickname as string,
    imageUrl: member.profile?.photo?.url as string,
  };
}
